import React from 'react'

import styled from 'styled-components'
import {
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
} from '@material-ui/core'
import { useTranslation } from 'next-i18next'

import { SUPPORT_BRAND_PACKAGING } from '../../fyndiqInfo'

const Content = styled(CardContent)`
  padding-bottom: 0;
`

const Link = styled.a`
  text-decoration: none;
`

const BrandPackagingInfoCard = () => {
  const { t } = useTranslation('common')

  return (
    <Card>
      <Content>
        <Typography color="textSecondary" gutterBottom>
          {t('fyndiq_branded_packaging')}
        </Typography>
      </Content>
      <Content>{t('brand_packaging_info')}</Content>
      <CardActions>
        <Link href={SUPPORT_BRAND_PACKAGING} target="_blank" rel="noreferrer">
          <Button size="small">{t('Learn more')}</Button>
        </Link>
      </CardActions>
    </Card>
  )
}

export default BrandPackagingInfoCard
