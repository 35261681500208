import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Alert } from '@material-ui/lab'

const MessagAlert = styled(Alert)`
  flex: 1;
`

const Message = ({ type, label }) => {
  return (
    <MessagAlert severity={type} variant="outlined" data-cy="message-text">
      {label}
    </MessagAlert>
  )
}

Message.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
}

Message.defaultProps = {
  type: 'info',
}

export default Message
