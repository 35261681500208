import { Market } from 'types/signup'

const SUPPORT_URL_SE = 'fyndiq.se/kundservice/'
const SUPPORT_URL_DK = 'fyndiq.dk/kundeservice/kontakt/'
const SUPPORT_URL_FI = 'fyndiq.fi/asiakaspalvelu/ota-yhteytta/'
const SUPPORT_URL_NO = 'fyndiq.no/kundeservice/kontakt/'

const PICKING_LIST_ADDRESS = {
  name: 'Fyndiq',
  co: 'C/O CDON',
  street: 'Södergatan 22',
  postal: '211 34 Malmö',
}

const SUPPORT_GUIDE = 'https://support.fyndiq.se/hc/sections/360007536311'
const SUPPORT_BRAND_PACKAGING =
  'https://support.fyndiq.se/hc/en-se/articles/4406460970132'

const getSupportURL = (market: Market) => {
  switch (market) {
    case 'DK':
      return SUPPORT_URL_DK
    case 'FI':
      return SUPPORT_URL_FI
    case 'NO':
      return SUPPORT_URL_NO
    default:
      return SUPPORT_URL_SE
  }
}

export {
  getSupportURL,
  PICKING_LIST_ADDRESS,
  SUPPORT_GUIDE,
  SUPPORT_BRAND_PACKAGING,
}
