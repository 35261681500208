import { Button, ButtonProps, CircularProgress } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

const ButtonWrapper = styled.div`
  margin-top: 10px;
  position: relative;
`

const Spinner = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`

export const LoadingButton = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<ButtonProps & { isLoading: boolean }>
>(({ children, isLoading, ...props }, ref) => (
  <ButtonWrapper>
    <Button {...props} disabled={props.disabled || isLoading} ref={ref}>
      {children}
    </Button>
    {isLoading && <Spinner size={24} />}
  </ButtonWrapper>
))
