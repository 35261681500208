import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'next-i18next'
import BrandPackagingInfoCard from './welcome/BrandPackagingInfoCard'

const WelcomeContainer = styled.div``

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`

const ContentCard = styled.div`
  flex: 0 50%;
`

const Padding = styled.div`
  padding: 5px;
`

type WelcomeScreenProps = {
  merchantName: string
}

const WelcomeScreen = ({ merchantName }: WelcomeScreenProps) => {
  const { t } = useTranslation('common')

  return (
    <WelcomeContainer>
      <Typography
        align="center"
        variant="h4"
        color="textPrimary"
        paragraph
        data-cy="welcome-header"
      >
        {t('Welcome')} {merchantName}
      </Typography>

      <ContentWrapper>
        <ContentCard>
          <Padding>
            <BrandPackagingInfoCard />
          </Padding>
        </ContentCard>
      </ContentWrapper>
    </WelcomeContainer>
  )
}

WelcomeScreen.propTypes = {
  merchantName: PropTypes.string.isRequired,
}

export default WelcomeScreen
