import React from 'react'
import nextCookie from 'next-cookies'
import PropTypes from 'prop-types'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import LoginScreen from '../components/authentication/LoginScreen'
import WelcomeScreen from '../components/WelcomeScreen'

const Home = ({ merchantId, merchantToken, merchantName }) => {
  if (merchantId && merchantToken) {
    return <WelcomeScreen merchantName={merchantName} />
  }
  return <LoginScreen />
}

export const getServerSideProps = async (ctx) => {
  const { merchantId, merchantToken, merchantName } = nextCookie(ctx)
  return {
    props: {
      merchantId: merchantId || null,
      merchantToken: merchantToken || null,
      merchantName: merchantName || null,
      namespacesRequired: ['common', 'errors'],
      ...(await serverSideTranslations(ctx.locale, ['common', 'errors'])),
    },
  }
}

Home.propTypes = {
  merchantId: PropTypes.string,
  merchantToken: PropTypes.string,
  merchantName: PropTypes.string,
}

Home.defaultProps = {
  merchantId: null,
  merchantToken: null,
  merchantName: null,
}

export default Home
