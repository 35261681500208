import React from 'react'
import styled from 'styled-components'
import Router from 'next/router'
import { TextField, Paper, Typography, InputAdornment } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useTranslation } from 'next-i18next'
import { loginUserService, loginUser } from '../../services/auth'
import Message from '../general/Message'
import { customEvents } from '../../tracking'
import { LoadingButton } from '../LoadingButton/LoadingButton'

const { login: trackLoginEvent } = customEvents

const LoginContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`

const LoginBox = styled(Paper)`
  padding: 10px;
  flex-direction: column;
`

const PasswordToggle = styled(InputAdornment)`
  &:hover {
    cursor: pointer;
  }
`

const LoginScreen = () => {
  const { t } = useTranslation(['common', 'errors'])
  const btnLogin = React.useRef()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [merchantId, setMerchantId] = React.useState('')
  const [merchantToken, setMerchantToken] = React.useState('')
  const [showPassword, setShowPassword] = React.useState(false)
  const [clip, setClip] = React.useState(null)

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }

  const loginClick = () => {
    setLoading(true)
    setError(null)
    loginUserService({
      merchantId,
      merchantToken,
    })
      .then((result) => {
        if (result.error) {
          setError(t('errors:LOGIN_ERROR'))
        } else {
          loginUser(result.data)
          trackLoginEvent()
          Router.push('/')
        }
      })
      .catch(() => {
        setError(t('errors:LOGIN_ERROR'))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  React.useEffect(() => {
    // can only access clipboard.readText() when site running under SSL
    if (navigator?.clipboard?.readText) {
      navigator.clipboard
        .readText()
        .then((res) => {
          setClip(res)
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [])

  const isJSON = (j) => {
    try {
      JSON.stringify(JSON.parse(j))
      return true
    } catch (e) {
      return false
    }
  }
  React.useEffect(() => {
    try {
      if (isJSON(clip)) {
        const json = JSON.parse(clip)
        if (json?.id && json?.token) {
          setMerchantId(json.id)
          setMerchantToken(json.token)
          navigator.clipboard.writeText('')
          if (btnLogin.current) {
            setTimeout(() => {
              btnLogin.current.click()
            }, 500)
          }
        }
      }
    } catch (e) {
      console.error(e)
    }
  }, [clip])

  return (
    <LoginContainer>
      <LoginBox elevation={5} data-cy="login-box">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            loginClick()
          }}
        >
          <Typography align="center" variant="h4" color="textPrimary" paragraph>
            {t('Login')}
          </Typography>
          <TextField
            label="ID"
            variant="outlined"
            fullWidth
            margin="dense"
            autoComplete="username"
            value={merchantId}
            onChange={(e) => setMerchantId(e.target.value.trim())}
            data-cy="login-merchant-id"
          />
          <TextField
            label="Token"
            variant="outlined"
            fullWidth
            margin="dense"
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            value={merchantToken}
            onChange={(e) => setMerchantToken(e.target.value.trim())}
            data-cy="login-merchant-token"
            InputProps={{
              endAdornment: (
                <PasswordToggle position="end" onClick={toggleShowPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </PasswordToggle>
              ),
            }}
          />
          <LoadingButton
            ref={btnLogin}
            variant="contained"
            type="submit"
            fullWidth
            color="primary"
            isLoading={loading}
          >
            {t('Login')}
          </LoadingButton>
          {error && <Message type="error" label={error} />}
        </form>
      </LoginBox>
    </LoginContainer>
  )
}

export default LoginScreen
